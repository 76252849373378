import * as React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { PAGES } from '../config/constants';
import Layout from '../components/layout';
import { withQuery } from '../utils/withQuery';
import Heading from '../components/heading';
import ExtraContent from '../components/extra-content';

const List = styled.ul`
  padding: 0;
  list-style: none;
  
  a {
    color: inherit;
  }
`;

const Item = styled.li`
  margin-bottom: 2rem;
`;

const Info = styled.span`
  font-size: 1rem;
`;

const Title = styled(Link)`
  display: block;
  font-size: 1.3125rem;
  line-height: 2rem;
  margin-top: 1.4rem;
  margin-bottom: .25rem;
`;

const Italic = styled.span`
  font-style: italic;
`;

const Poems = ({ poems, extraContent: { title, content } }) => {
  return (
    <Layout
      pageTitle="Poems"
      pageDescription="Selected poems from Linda Black's poetry collections, including Slant, The Son of a Shoemaker Slant and Root"
      breadcrumbTrail={[PAGES.POEMS]}
    >
      <Heading>Poems</Heading>
      <nav>
        <List>
          {poems.map(({ id, title, from, fromPath, year, path }) => (
            <Item key={id}>
              <Title to={path}>
                {title}
              </Title>
              <Info>
                {from ? <><Italic>from</Italic> <Link to={fromPath}>{from}</Link></> : ''}
                {year ? `, ${year}` : ''}
              </Info>
            </Item>
          ))}
        </List>
      </nav>
      <ExtraContent>
        <h2>{title}</h2>
        {content}
      </ExtraContent>
    </Layout>
  );
};

export const query = graphql`
  query Poems {
    allContentfulPoem(sort: {fields: [from___publishDate, title], order: [DESC, ASC]}) {
      edges {
        node {
          id
          title
          from {
            id
            title
            publisher
            publishDate
            fields {
              path
            }
          }
          fields {
            path
          }
        }
      }
    }
    contentfulPage(contentful_id: { eq: "1mVKOjG2tDdr9siTjm8WE8" }) {
      title
      content {
        raw
      }
    }
  }
`;

const mapDataToProps = ({ allContentfulPoem, contentfulPage: { title, content } }) => ({
  poems: allContentfulPoem.edges.map(({ node }) => ({
    id: node.id,
    path: node.fields.path,
    title: node.title,
    from: node.from?.title,
    fromPath: node.from?.fields.path,
    year: new Date(node.from?.publishDate).getFullYear(),
  })),
  extraContent: {
    title,
    content: documentToReactComponents(
      JSON.parse(content.raw),
      {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) => (
            <a href={node.data.uri} target="_blank" rel="noreferrer noopener">
              {children}
            </a>
          ),
        },
      },
    ),
  },
});

export default withQuery(mapDataToProps)(Poems);
